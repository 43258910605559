
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { UndefStr } from "../types";
import { getFaqInfo, downloadFile } from "@/API/faq";
export default defineComponent({
  setup() {
    const route = useRoute();
    const faqId = route.params.faqId;
    const category = ref<UndefStr>(undefined);
    const question = ref("");
    const answer = ref("");
    const fileList = ref([]);
    const downloadAttachment = (file: any) => {
      downloadFile(file.documentId || file.id).then((res: any) => {
        window.open(res);
      });
    };
    onMounted(() => {
      if (faqId) {
        getFaqInfo({ id: faqId }).then((res: any) => {
          answer.value = res.answer;
          category.value = res.category;
          question.value = res.question;
          fileList.value = res.files;
        });
      }
    });
    return {
      category,
      question,
      answer,
      fileList,
      downloadAttachment,
    };
  },
});
